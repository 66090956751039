import React from "react";
import "./CourseFilter.css";

const CourseFilter = ({ categories, places, selectedFilters, onFilterChange }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    onFilterChange({ ...selectedFilters, [name]: value });
  };

  return (
    <div className="filters">
      <select
        name="course"
        value={selectedFilters.course}
        onChange={handleChange}
      >
        {categories.map((course, index) => (
          <option key={index} value={course}>
            {course}
          </option>
        ))}
      </select>

      <select
        name="place"
        value={selectedFilters.place} // Controlled by state
        onChange={handleChange}
      >
        <option value="" disabled>
          Select a place
        </option>
        {places.map((place, index) => (
          <option key={index} value={place}>
            {place}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CourseFilter;
