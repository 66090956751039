import React from "react";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#1d1d1d",
    },
    text: {
      primary: "#ffffff",
      secondary: "#b0b0b0",
    },
  },
});

const CourseCard = ({
  title,
  subheader,
  image,
  attribution,
  description,
  method,
  slug, // The slug for the Explore link
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [showAttribution, setShowAttribution] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const toggleAttribution = () => {
    setShowAttribution(!showAttribution);
  };

  const course = "agriculture";

  return (
    <ThemeProvider theme={darkTheme}>
      <Card sx={{ maxWidth: 345, backgroundColor: "background.paper" }}>
        <CardHeader
          title={title}
          subheader={subheader}
          sx={{ color: "text.primary" }}
        />
        <div style={{ position: "relative" }}>
          {!showAttribution ? (
            <>
              <CardMedia
                component="img"
                height="194"
                image={image}
                alt={title || "Recipe Image"}
                loading="lazy"
              />
              <IconButton
                aria-label="info"
                onClick={toggleAttribution}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  },
                }}
              >
                <InfoIcon />
              </IconButton>
            </>
          ) : (
            <div
              style={{
                height: "194px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "background.default",
                position: "relative",
              }}
            >
              <Typography sx={{ color: "text.primary", textAlign: "center" }}>
                {attribution}
              </Typography>
              <IconButton
                aria-label="back"
                onClick={toggleAttribution}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  },
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </div>
          )}
        </div>
        <CardContent>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="bookmark" sx={{ color: "text.primary" }}>
            <BookmarkIcon />
          </IconButton>
          <IconButton aria-label="share" sx={{ color: "text.primary" }}>
            <ShareIcon />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon sx={{ color: "text.primary" }} />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography sx={{ marginBottom: 2, color: "text.primary" }}>
              Method:
            </Typography>
            {method.map((step, index) => (
              <Typography
                key={index}
                sx={{ marginBottom: 2, color: "text.secondary" }}
              >
                {step}
              </Typography>
            ))}
          </CardContent>
        </Collapse>
        <CardActions>
          {/* <Link to={`/colleges/${slug}`} style={{ textDecoration: "none" }}> */}
          <Link to={`/colleges/${course}`} style={{ textDecoration: "none" }}>
            <Typography
              variant="body2"
              sx={{
                color: "text.primary",
                textAlign: "center",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Explore
            </Typography>
          </Link>
        </CardActions>
      </Card>
    </ThemeProvider>
  );
};

export default CourseCard;
