import ComingSoon from "../pages/Main/ComingSoon";


const jobsRoutes = [
  // { path: "/jobs", element: <Jobs /> },
  // { path: "/jobs/:course", element: <CourseJobs /> },
  // { path: "/jobs/:course/:field", element: <FieldCourseJobs /> },
  { path: "/jobs", element: <ComingSoon /> },
  { path: "/jobs/:course", element: <ComingSoon /> },
  { path: "/jobs/:course/:field", element: <ComingSoon /> },
];

export default jobsRoutes;
