import React from "react";
import ImageHeader from "../../components/Main/ImageHeader";
const ComingSoon = () => {
  return (
    <>
      <ImageHeader
        heading="Oops.."
        text="Patience, please! We’re crafting something you’ll love."
        backgroundImage="https://images.unsplash.com/photo-1511207538754-e8555f2bc187?q=80&w=1527&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
      <div className="page-not-found">
        <h1>We are working on it.</h1>
        <p>Coming Soon...</p>
      </div>
    </>
  );
};
export default ComingSoon;
