import React from "react";
import ImageHeader from "../../components/Main/ImageHeader";
const PageNotFound = () => {
  return (
    <>
      <ImageHeader
        heading="Oops.."
        text="Recheck the url"
        backgroundImage="https://images.unsplash.com/photo-1516345079912-c3e011a5a848?q=80&w=1485&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
      <div className="page-not-found">
        <h1>404 - Page Not Found</h1>
        <p>Oops! The page you're looking for doesn't exist.</p>
      </div>
    </>
  );
};
export default PageNotFound;
