import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import CollegeCard from "../../components/Colleges/CollegeCard.js"; // Use the new Delta component
import PlaceFilter from "../../components/Colleges/PlaceFilter.js"; // Updated Filters import
import { Grid } from "@mui/material"; // Import the Grid component from MUI
import styles from "./PlaceCourseColleges.module.css";

const PlaceCourseColleges = () => {
  const { course: urlCourse, place: urlPlace } = useParams(); // Updated route params
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState("");
  const [courses, setCourses] = useState([]);
  const [places, setPlaces] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    course: urlCourse || "",
    place: urlPlace || "",
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        const collegesData = await import("../../data/Colleges/collegesData.js");

        const uniqueCourses = [
          ...new Set(collegesData.default.map((college) => college.category)),
        ];
        const uniquePlaces = [
          ...new Set(
            collegesData.default.flatMap((college) => [
              college.city,
              college.state,
            ])
          ),
        ].map((place) => place && place.trim()); // Ensure no trailing spaces

        setCourses(uniqueCourses);
        setPlaces(uniquePlaces);

        // Sync filters and URL on initial load
        filterColleges(collegesData.default, {
          course: urlCourse || "",
          place: urlPlace || "",
        });
      } catch (err) {
        setError("Error loading colleges data");
        console.error(err);
      }
    };

    loadData();
  }, [urlCourse, urlPlace]); // Reload data if URL changes

  useEffect(() => {
    const applyFilters = async () => {
      try {
        const collegesData = await import("../../data/Colleges/collegesData.js");
        filterColleges(collegesData.default, selectedFilters);
      } catch (err) {
        setError("Error applying filters");
        console.error(err);
      }
    };

    applyFilters();

    // Sync URL with filters
    const formattedPlace = selectedFilters.place
      ? selectedFilters.place.toLowerCase().replace(/\s+/g, "-")
      : "";
    const newPath = `/colleges/${selectedFilters.course.toLowerCase()}/${formattedPlace}`;

    if (
      newPath !==
      `/colleges/${urlCourse?.toLowerCase()}/${urlPlace?.toLowerCase() || ""}`
    ) {
      navigate(newPath, { replace: true });
    }
  }, [selectedFilters, navigate, urlCourse, urlPlace]);

  const filterColleges = (data, filters) => {
    const { course, place } = filters;

    const normalizeString = (str) =>
      str.toLowerCase().replace(/-/g, " ").trim();

    const normalizedPlace = place ? normalizeString(place) : "";

    const filtered = data.filter((college) => {
      const matchesCourse = course
        ? college.category.toLowerCase() === course.toLowerCase()
        : true;
      const matchesPlace = normalizedPlace
        ? normalizeString(college.city) === normalizedPlace ||
          normalizeString(college.state) === normalizedPlace
        : true;

      return matchesCourse && matchesPlace;
    });

    setFilteredData(filtered);
    setError(filtered.length === 0 ? "No colleges found for the selected filters." : "");
  };

  const handleFilterChange = (newFilters) => {
    setSelectedFilters(newFilters);
  };

  return (
    <>
      <Helmet>
        <title>Top Colleges</title>
        <meta
          name="description"
          content="Find top colleges across various courses, cities, and states. Apply filters to find your best options."
        />
      </Helmet>
      <div className={styles["colleges-page"]}>
        <h1>Top Colleges</h1>

        <PlaceFilter
          courses={courses} // Updated to use "courses"
          places={places}
          selectedFilters={selectedFilters}
          onFilterChange={handleFilterChange}
        />

        <div className={styles["cards-container"]}>
          {filteredData.length > 0 ? (
            <Grid container spacing={3}>
              {filteredData.map((college, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={college.slugNumber}>
                  <CollegeCard
                    name={college.name}
                    source={college.source || "N/A"}
                    description={college.description || "Details not available"}
                    rank={index + 1}
                    photo={college.photo}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <div className={styles["no-data-message"]}>{error || "No colleges available."}</div>
          )}
        </div>

        <button className={"back-button"} onClick={() => navigate(-1)}>
          Go Back
        </button>
      </div>
    </>
  );
};

export default PlaceCourseColleges;
