import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import CollegeCard from "../../components/Colleges/CollegeCard.js"; // Updated to use CollegeCard
import CourseFilter from "../../components/Colleges/CourseFilter.js"; // Updated Filters import
import { Grid } from "@mui/material"; // Import Grid from MUI
import styles from "./CourseColleges.module.css";

const CourseColleges = () => {
  const { course: urlCourse } = useParams(); // Changed 'category' to 'course'
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState("");
  const [places, setPlaces] = useState([]);
  const [courses, setCourses] = useState([]); // Updated to 'courses'
  const [selectedFilters, setSelectedFilters] = useState({
    course: urlCourse || "",
    place: "",
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        const collegesData = await import("../../data/Colleges/collegesData.js");

        const uniqueCourses = [
          ...new Set(collegesData.default.map((college) => college.category)), // Changed 'category' to 'course'
        ];
        const uniquePlaces = [
          ...new Set(
            collegesData.default
              .filter(
                (college) =>
                  college.category.toLowerCase() === urlCourse.toLowerCase() // Changed 'category' to 'course'
              )
              .flatMap((college) => [college.city, college.state])
          ),
        ];

        setPlaces(uniquePlaces);
        setCourses(uniqueCourses); // Updated to 'courses'
        filterColleges(collegesData.default, {
          course: urlCourse,
          place: "",
        });
      } catch (err) {
        setError("Error loading colleges data");
        console.error(err);
      }
    };

    loadData();
  }, [urlCourse]);

  useEffect(() => {
    const applyFilters = async () => {
      try {
        const collegesData = await import("../../data/Colleges/collegesData.js");
        filterColleges(collegesData.default, selectedFilters);
  
        const formattedCourse = selectedFilters.course.toLowerCase();
        let newPath = `/colleges/${formattedCourse}`;
  
        // If a place is selected, add it to the URL
        if (selectedFilters.place) {
          const formattedPlace = selectedFilters.place
            .toLowerCase()
            .replace(/\s+/g, "-");
          newPath = `/colleges/${formattedCourse}/${formattedPlace}`;
        }
  
        navigate(newPath, { replace: true });
      } catch (err) {
        setError("Error applying filters");
        console.error(err);
      }
    };
  
    applyFilters();
  }, [selectedFilters, navigate]);
  

  const filterColleges = (data, filters) => {
    const { course, place } = filters;
    const filtered = data.filter((college) => {
      const matchesCourse = course
        ? college.category.toLowerCase() === course.toLowerCase()
        : true; // Changed 'category' to 'course'
      const matchesPlace = place
        ? college.city.toLowerCase() === place.toLowerCase() ||
          college.state.toLowerCase() === place.toLowerCase()
        : true;

      return matchesCourse && matchesPlace;
    });

    setFilteredData(filtered);
    setError(
      filtered.length === 0 ? "No colleges found for the selected filters." : ""
    );
  };

  return (
    <>
      <Helmet>
        <title>{`Top ${urlCourse} Colleges`}</title> {/* Changed 'category' to 'course' */}
        <meta
          name="description"
          content={`Explore the best ${urlCourse} colleges. Filter by place to find your top options.`}
        />
      </Helmet>
      <div className={styles["all-colleges-page"]}>
        <h1>Top {urlCourse} Colleges</h1> {/* Changed 'category' to 'course' */}

        <CourseFilter
          categories={courses} // Updated to 'courses'
          places={["Select a place", ...places]}
          selectedFilters={selectedFilters}
          onFilterChange={(filters) =>
            setSelectedFilters((prev) => ({ ...prev, ...filters }))
          }
        />

        <div className={styles["cards-container"]}>
          {filteredData.length > 0 ? (
            <Grid container spacing={3}>
              {filteredData.map((college, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={college.slugNumber}>
                  <CollegeCard
                    name={college.name}
                    source={college.source || "N/A"}
                    description={college.description || "Details not available"}
                    rank={index + 1}
                    photo={college.photo}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <div className={styles["no-data-message"]}>
              {error || "No colleges available."}
            </div>
          )}
        </div>

        <button
          className={styles["back-button"]}
          onClick={() => navigate(-1)}
        >
          Go Back
        </button>
      </div>
    </>
  );
};

export default CourseColleges;
